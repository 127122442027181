

// import {i18n} from '@/i18n';
import {mapGetters} from 'vuex';
export default {
  name:'app-image-background',
  props:['displayTitle','displayTitleAfterDrugIndex',
  'image',
  'title',
  'subtitle1',
  'subtitle2',
  'subtitle3',
  'subtitle4',
  'btn',
  'btnDesc',
  'service',
  'addBtnLoading',
  'addBtnDisabled'],
  data(){
    return{
        prescriptionDialog:false,
        publishDialog:false,
         tab: ''
    }
  },
  
  methods:{
      i18n(key, args) {
      return this.$t(key, args);
    },
    addNewItem(){
        this.$emit('addNewItem')
        // if(this.service == "presc"){
        //     this.prescriptionDialog = true
        // }
    },
    publish(){

    },
    close(){

    },
    closeSuccessDialog(){

    }
  },
  watch:{
  },
  mounted(){

  },
  
   computed:{
     isScreenXs(){
      return this.$q.screen.lt.md && this.$q.screen.lt.sm && this.$q.screen.lt.lg && this.$q.screen.lt.xl
    },
    ...mapGetters({
        is_screen_xs:'layout/is_screen_xs',
        drugIndexHomeSearch:'home/drugIndexHomeSearch'
    })
  },
  async created(){
 
  }
}
