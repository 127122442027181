
import { mapActions, mapGetters } from "vuex";
import PrescriptionFormModal from "@/modules/prescriptions/components/prescription-form-modal.vue";
import PrescriptionsListTable from "@/modules/prescriptions/components/prescriptions-list-table.vue";
import SuccessDialog from "@/modules/services/components/success-dialog.vue";
// import {i18n} from '@/i18n';
import i18n from "@/vueI18n";
import ImageBackground from "@/modules/home/components/image-background.vue";

import firebase from "firebase/app";
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';

// import "firebase/firestore";
// import "firebase/database";
// import moment from "moment";
export default {
  data() {
    return {
      prescriptionDialog: false,
      publishDialog: false,
      modalVisible: false,
      dialogVisible: false,
      displayTitle: false,

      currentMonth: undefined,
      currentYear: undefined,
      prescriptionMaxCount: undefined,
      addedPrescInMonth: 0,
      userPrescriptionsInMonth: [],
      userPrescription: {},
      canNotAdd: false,
      showErrorModal: false,
      modalErrorVisible: false,
      dialogErrorVisible: false,

      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      successMsgTitle: '',
      successMsgDesc: ''
      // vuexfire
    };
  },
  components: {
    [PrescriptionFormModal.name]: PrescriptionFormModal,
    [PrescriptionsListTable.name]: PrescriptionsListTable,
    [SuccessDialog.name]: SuccessDialog,
    [ImageBackground.name]: ImageBackground,
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      setHeaderBg: "layout/setHeaderBg",
      setNavbar: "layout/setNavbar",
      setFooter: "layout/setFooter",
      setCurrentRoute: "prescriptions/list/setCurrentRoute",
      // vuexfire
      doFetch: "prescriptions/list/doFetch",
      setMountedTable: "prescriptions/list/setMountedTable",
      resetCursor: "prescriptions/list/resetCursor",
      doFetchFromRoute: "prescriptions/list/doFetchFromRoute",
      setRows: "prescriptions/list/setRows",
      setLoadingAddMorePrescriptions:'prescriptions/list/setLoadingAddMorePrescriptions'
    }),
    scrollHandler(scroll) {
      if (scroll.position == 0) {
        this.setHeaderBg("bg-transparent");
      } else {
        // this.setHeaderBg('bg-shadow animate__animated animate__fadeInDown')
        this.setHeaderBg("bg-shadow");
      }
    },
    closeErrorModal() {
      this.dialogErrorVisible = false;
      setTimeout(() => {
        this.modalErrorVisible = false;
      }, 500);
    },
    close() {
      // this.prescriptionDialog = false
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },


    async setSuccessDialogData(userListener){
            let addedPrescriptionInMonth = 0
            var today = new Date();
            if(userListener.prescription || userListener.prescription != undefined){            
                for (const [key] of Object.entries(userListener.prescription)) {
                    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                    if(key >= firstDay && key < lastDay){
                        addedPrescriptionInMonth = addedPrescriptionInMonth + 1
                    }
                }
                await firebase.database().ref('setting/prescription').on('value',(snapshot) => {
                    this.prescriptionMaxCount = snapshot.val()
                    this.successMsgTitle =  this.i18n('drugDeal.prescriptions.successMsgTitle')
                    if(!this.isRTL){
                        this.successMsgDesc = `(This month posts: ${addedPrescriptionInMonth}, Maximum posts: ${this.prescriptionMaxCount})`
                    }
                    else{
                        this.successMsgDesc = `(هذا الشهر: ${addedPrescriptionInMonth}، الحد الأقصى شهرياً: ${this.prescriptionMaxCount})`
                    }    
                    
                    this.prescriptionDialog = false;
                    this.publishDialog = true;
                    this.close();
                });
            }
        },
    
    async publish() {
      await this.setSuccessDialogData(this.userListener)

      // this.prescriptionDialog = false;
      // this.publishDialog = true;


      // this.close();
    },
    closeSuccessDialog() {
      this.publishDialog = false;
    },
    addNewItem() {
      // this.prescriptionDialog = true
      if (this.canNotAdd) {
        this.modalErrorVisible = true;
        this.dialogErrorVisible = true;
        // this.showErrorModal = true
      } else {
        this.modalVisible = true;
        this.dialogVisible = true;
      }
    },
    changePrescriptionHeader(value) {
      this.displayTitle = value;
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async addMorePrescriptions(userListener) {
      // if(this.userListener){
      this.setLoadingAddMorePrescriptions(true)
      this.userPrescription = userListener.prescription;
      this.addedPrescInMonth = 0;
      var today = new Date();
      if (this.userPrescription || this.userPrescription != undefined) {
        for (const [key] of Object.entries(this.userPrescription)) {
          var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
          var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
          if (key >= firstDay && key < lastDay) {
            // if(!this.userPrescriptionsInMonth.includes(value)){
            //     this.userPrescriptionsInMonth.push(value)
            this.addedPrescInMonth = this.addedPrescInMonth + 1;
            // }
          }
        }
        await firebase
          .database()
          .ref("setting/prescription")
          .on("value", (snapshot) => {
            this.prescriptionMaxCount = snapshot.val();
            if (
              this.addedPrescInMonth == this.prescriptionMaxCount ||
              this.addedPrescInMonth > this.prescriptionMaxCount
            ) {
              this.canNotAdd = true;
            } else {
              this.canNotAdd = false;
            }
            this.setLoadingAddMorePrescriptions(false)
          });
      }
      else{
        this.setLoadingAddMorePrescriptions(false)
      }
      // }
    },

    async navigateBackwardForward() {
      if (
        this.$route.name == "prescriptions" ||
        this.$route.name == "my-prescriptions"
      ) {
        if (
          this.$route.query.page &&
          this.$route.query.page > 0 &&
          this.isNumeric(this.$route.query.page)
        ) {
          this.page = this.$route.query.page
          // debugger
          await this.doFetchFromRoute({
            page: this.$route.query.page,
            limit: this.limit,
          });
        } else {
          this.page = 1
          await this.doFetch({ page: 1, limit: this.limit });
        }
      } 
      
    },
  },
  computed: {
    lang(){
      // debugger    
      return i18n.locale
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      user: "prescriptions/list/user",
      userLoading: "auth/userLoading",
      userListener: "auth/userListener",
      rows: "prescriptions/list/rows",
      currentRoute: "prescriptions/list/currentRoute",
      loadingAddMorePrescriptions:'prescriptions/list/loadingAddMorePrescriptions'
    }),
    isRTL() {
      return localStorage.getItem("language") == "ar";
    },
    title() {
      return this.$t("drugDeal.services.prescriptions");
    },
    subtitle1() {
      return this.$t("drugDeal.prescriptions.viewAndAddPrescription");
    },
    imageBg() {
      return "https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fprescriptions-bg.webp?alt=media&token=657fe049-937e-41cf-ad43-21a20e5d1e03";
    },
    btnDesc() {
      return this.$t("drugDeal.prescriptions.addNewPrescription");
    },
    limit() {
      const { screen } = this.$q;
      if (screen.lt.sm) {
        return 4;
      }
      if (screen.lt.md) {
        return 6;
      }
      if (screen.lt.lg) {
        return 8;
      }
      return 9;
    },
  },

  watch: {
    lang(){
      document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('drugDeal.services.prescriptions') 
    },
    async userListener(newval) {
      if (this.userListener) {
        this.addMorePrescriptions(newval);
      }
    },
  },
  mounted() {
    this.setHeaderBg("bg-transparent");
    this.setNavbar("services");
    this.setFooter(false);
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardForward();
    };
  },

  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.prescriptions') 

    this.setCurrentRoute(this.$router.currentRoute.name);
    if(this.userListener) {
      this.addMorePrescriptions(this.userListener);
    }
      AnalyticsManager.logScreen('View Prescription Details', 'PrescriptionProfile');
  },
  destroyed() {
    this.setLoadingAddMorePrescriptions(true)
    this.setMountedTable(false);
    this.resetCursor();
  },
};
