import { render, staticRenderFns } from "./image-background.vue?vue&type=template&id=20ce79b9&scoped=true&"
import script from "./image-background.vue?vue&type=script&lang=js&"
export * from "./image-background.vue?vue&type=script&lang=js&"
import style0 from "./image-background.vue?vue&type=style&index=0&id=20ce79b9&prod&lang=scss&"
import style1 from "./image-background.vue?vue&type=style&index=1&id=20ce79b9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20ce79b9",
  null
  
)

export default component.exports
import {QImg,QInput,QIcon,QBtn,QInnerLoading,QSpinnerIos,QField,QTab,QTabs} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QImg,QInput,QIcon,QBtn,QInnerLoading,QSpinnerIos,QField,QTab,QTabs})
